const BASE_URL = process.env.REACT_APP_API_URL;

export const fetchPortfolios = async () => {
  const response = await fetch(`${BASE_URL}/portfolios`);
  if (!response.ok) {
    throw new Error('Failed to fetch portfolios');
  }
  const data = await response.json();
  return data;
};

export const fetchPortfolioById = async (id: string) => {
  const response = await fetch(`${BASE_URL}/portfolios/${id}`);
  if (!response.ok) {
    throw new Error('Failed to fetch portfolio');
  }
  const data = await response.json();
  return data;
};
