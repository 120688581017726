import React from 'react';
import { Link } from 'react-router-dom';
import './WhatWeDo.css';
import fotrImage from '../../assets/img/whatwedo/Fotr.26b499b03ea937f7b407.png';
import caravanImage from '../../assets/img/whatwedo/Caravan.1dafa20c8b0a271a9fd8.png';
import golfImage from '../../assets/img/whatwedo/golf.00a589de58142e15dafa.jpeg';
import conversationsImage from '../../assets/img/whatwedo/fotr-conv.4d07e55ba35e577e544e.jpeg';
import avpsImage from '../../assets/img/whatwedo/avps.jpg'; // Replace with your actual image paths
import pradaImage from '../../assets/img/whatwedo/prada.jpg'; // Replace with your actual image paths

const WhatWeDo: React.FC = () => {
  return (
    <section id="what-we-do" className="what-we-do">
      <div className="container">
        <div className="section-title">
          <h2>What We Do</h2>
          <p>Fashion on the Road (FOTR) transforms fashion through creativity, sustainability, and community empowerment.</p>
        </div>

        <div className="row">
          <div className="col-lg-4 col-md-6 d-flex align-items-stretch">
            <div className="icon-box">
              <div className="image">
                <img src={fotrImage} alt="FOTR Duka" />
              </div>
              <h4><Link to="/what-we-do/fotr-duka">FOTR Duka</Link></h4>
              <p>A vibrant marketplace for designers to showcase and sell their creations.</p>
            </div>
          </div>

          <div className="col-lg-4 col-md-6 d-flex align-items-stretch mt-4 mt-md-0">
            <div className="icon-box">
              <div className="image">
                <img src={caravanImage} alt="FOTR Caravan" />
              </div>
              <h4><Link to="/what-we-do/fotr-caravan">FOTR Caravan</Link></h4>
              <p>Promotes reuse and recycling of clothing and textile waste through collection bins.</p>
            </div>
          </div>

          <div className="col-lg-4 col-md-6 d-flex align-items-stretch mt-4 mt-lg-0">
            <div className="icon-box">
              <div className="image">
                <img src={golfImage} alt="FOTR Sports Series" />
              </div>
              <h4><Link to="/what-we-do/fotr-sports-series">FOTR Sports Series</Link></h4>
              <p>Annual cultural charity sports tournament uniting fashion and sports communities.</p>
            </div>
          </div>

          <div className="col-lg-4 col-md-6 d-flex align-items-stretch mt-4">
            <div className="icon-box">
              <div className="image">
                <img src={conversationsImage} alt="FOTR Conversations" />
              </div>
              <h4><Link to="/what-we-do/fotr-conversations">FOTR Conversations</Link></h4>
              <p>Cultural exchange events celebrating Kenyan fashion, art, and heritage.</p>
            </div>
          </div>

          <div className="col-lg-4 col-md-6 d-flex align-items-stretch mt-4">
            <div className="icon-box">
              <div className="image">
                <img src={avpsImage} alt="FOTR AVPS and KSBDC" />
              </div>
              <h4><Link to="/what-we-do/fotr-avps-ksbdc">FOTR AVPS and KSBDC</Link></h4>
              <p>Carole Kinoti is engaged in a dynamic agreement with Strathmore University, operating as the lead consultant to support the Strathmore Business School - USAID Strategic Partnerships Program.</p>
            </div>
          </div>

          <div className="col-lg-4 col-md-6 d-flex align-items-stretch mt-4">
            <div className="icon-box">
              <div className="image">
                <img src={pradaImage} alt="FOTR- PRADA/ UNFPA" />
              </div>
              <h4><Link to="/what-we-do/fotr-prada-unfpa">FOTR- PRADA/ UNFPA</Link></h4>
              <p>A remarkable collaboration with the Madonna Resource Centre to empower and inspire aspiring four female entrepreneurs sponsored by Prada.</p>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default WhatWeDo;
